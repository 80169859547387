import React, { useState, useEffect } from "react";
import axios from "axios";

const HighestLeads = () => {
    const [leads, setLeads] = useState([]);
    const [highestLead, setHighestLead] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/dashboard/leads/data`);
                const data = response.data.data.leadsData;
                const highestLeadSource = response.data.data.highestLeadSource
                setLeads(data);
                setHighestLead(highestLeadSource)              

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    const totalLeadData = leads?.reduce((total, lead) => total + lead.LeadData, 0);
    const calculatePercentage = (categorySum) => {
        return (categorySum / totalLeadData) * 100;
    };

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title mb-4">Highest Lead Source</h4>
                <div className="text-center">
                    <div className="mb-4">
                        <i className="bx bx-map-pin text-primary display-4" />
                    </div>
                    <h3>{highestLead?.LeadData}</h3>
                    <p>{highestLead?.source_from?.charAt(0)?.toUpperCase() + highestLead?.source_from?.slice(1)}</p>
                </div>
                <div className="table-responsive mt-4">
                    <table className="table align-middle table-nowrap">
                        <tbody>
                            {leads.map((lead) => {
                                let progressStyle = {};
                                let progressBarClass = "";
                                let categorySum = 0;
                                if (lead.source_from === "Tablib") {
                                    categorySum = leads?.filter(l => l.source_from === "Tablib")?.reduce((sum, l) => sum + l.LeadData, 0);
                                    progressBarClass = "progress-bar bg-primary rounded";
                                } else if (lead.source_from === "esyschooling") {
                                    categorySum = leads?.filter(l => l.source_from === "esyschooling")?.reduce((sum, l) => sum + l.LeadData, 0);
                                    progressBarClass = "progress-bar bg-success rounded";
                                } else if (lead.source_from === "others") {
                                    categorySum = leads?.filter(l => l.source_from === "others")?.reduce((sum, l) => sum + l.LeadData, 0);
                                    progressBarClass = "progress-bar bg-warning rounded";
                                }

                                const percentage = calculatePercentage(categorySum);
                                progressStyle = { width: `${percentage}%` };
                                return (
                                    <tr key={lead?.id}>
                                        <td style={{ width: "30%" }}>
                                            <p className="mb-0">{lead?.source_from?.charAt(0)?.toUpperCase() + lead?.source_from?.slice(1)}</p>
                                        </td>
                                        <td style={{ width: "25%" }}>
                                            <h5 className="mb-0">{lead?.LeadData}</h5>
                                        </td>
                                        <td>
                                            <div className="progress bg-transparent progress-sm">
                                                <div
                                                    className={progressBarClass}
                                                    role="progressbar"
                                                    style={progressStyle}
                                                    aria-valuenow={percentage}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );

}

export default HighestLeads