import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useProfile from './useProfile';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyProfile = () => {
    const { profileData, loading, error, refetchProfileData } = useProfile();

    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        if (profileData) {
            setUserName(profileData.userName);
            setEmail(profileData.email);
            setUserId(profileData.id);
        }
    }, [profileData]);

    const handleSave = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASEURL}/app/update/user/profile-detail`, {
                userName,
                email,
                userId
            });

            toast.success('Profile updated successfully!');
            refetchProfileData();
        } catch (err) {
            toast.error(
                err.response?.data?.message || 'Failed to update profile. Please try again.'
            );
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="card overflow-hidden">
                    <div className="bg-primary bg-soft">
                        <div className="row">
                            <div className="col-7">
                                <div className="text-primary p-3">
                                    <h5 className="text-primary">Welcome Back!</h5>
                                    <p>SRMPS</p>
                                </div>
                            </div>
                            <div className="col-5 align-self-end">
                                <img
                                    src="assets/images/profile-img.png"
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="avatar-md profile-user-wid mb-4">
                                    <img
                                        src="assets/images/users/avatar.jpg"
                                        alt=""
                                        className="img-thumbnail rounded-circle"
                                    />
                                </div>
                                {/* Editable Fields */}
                                <div className="mb-3">
                                    <label htmlFor="userName" className="form-label">
                                        Username:
                                    </label>
                                    <input
                                        type="text"
                                        id="userName"
                                        className="form-control"
                                        value={userName}
                                        readOnly // Make the field read-only
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        Email:
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        value={email}
                                        readOnly // Make the field read-only
                                    />
                                </div>
                                {/* <button className="btn btn-primary" onClick={handleSave} readOnly >
                                    Save Changes
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
