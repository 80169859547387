import React, { useMemo, useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import Pagination from "../Pagination";
import { generateReviewReport } from "./excel";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";  // Import useLocation

const ErrorLogData = () => {
    const location = useLocation();  // Get location
    const { errorLogData } = location.state || {};  
    const [data, setData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(false);
    const [selectedSource, setSelectedSource] = useState('');
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        selectedSource: '',
    });


    useEffect(() => {
        const fetchLeads = async () => {
            setLoading(true);
            let apiUrl = `${process.env.REACT_APP_BASEURL}/app/get/error/log`;
            const finalSource = filters.selectedSource || ''; // Default source to empty string if not selected

            if (filters.startDate && filters.endDate) {
                const formattedStartDate = filters.startDate.toISOString().split('T')[0];
                const formattedEndDate = filters.endDate.toISOString().split('T')[0];
                apiUrl = `${apiUrl}?startDate=${formattedStartDate}&endDate=${formattedEndDate}&source_from=${finalSource}`;
            } else {
                apiUrl = `${apiUrl}?source_from=${finalSource}`;
            }

            try {
                const response = await axios.get(apiUrl);
                if (response.data && response.data.data) {
                    setData(response.data.data); // Update state with the response
                } else {
                    setData({ errorLog: [], totalErrorLogData: 0 }); // Handle empty response
                }
            } catch (error) {
                console.error('Error fetching leads:', error);
                toast.error('Failed to fetch leads');
            } finally {
                setLoading(false);
            }
        };

        // Fetch leads when filters change
        fetchLeads();
    }, [filters]);


    const validationSchema = Yup.object({
        startDate: Yup.date()
            .min(new Date('2000-01-01'), "Start date should be after 2000")
            .nullable(),
        endDate: Yup.date()
            .min(Yup.ref('startDate'), "End date should be greater then start date")
            .nullable(),
        selectedSource: Yup.string().nullable(),
    });

    // Formik setup for validation
    const formik = useFormik({
        initialValues: {
            startDate: null,
            endDate: null,
            selectedSource: '',
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            setFilters({
                startDate: values.startDate,
                endDate: values.endDate,
                selectedSource: values.selectedSource, // Ensure this updates
            });
        }
    });


    const handleSourceChange = (e) => {
        formik.setFieldValue('selectedSource', e.target.value);
    };

    const handleSearch = () => {
        formik.handleSubmit();
    };



    const filteredData = useMemo(() => {
        if (!data || !data.errorLog) return [];
        return data.errorLog.filter(lead => {
            const matchesGlobalFilter =
                lead.name?.toLowerCase().includes(globalFilter.toLowerCase()) ||
                lead.phone_number?.includes(globalFilter) ||
                lead.email?.toLowerCase().includes(globalFilter);

            const matchesSourceFilter =
                !filters.selectedSource || String(lead.source_from) === String(filters.selectedSource);

            return matchesGlobalFilter && matchesSourceFilter;
        });
    }, [globalFilter, filters.selectedSource, data]);


    const columns = useMemo(() => [
        {
            name: 'S.No.',
            selector: (row, index) => index + 1 + pageIndex * pageSize,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Class',
            selector: row => row.class,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
            sortable: true,
            width: '150px',
        },
        {
            name: 'Email',
            selector: row => (
                <span title={row.email}>
                    {row.email === "null" || !row.email?.trim() ? '' : row.email}
                </span>
            ),
            sortable: true,
            width: '250px',
        },
        {
            name: 'Source',
            selector: row => {
                if (row.source_from === 1) return 'Tablib';
                else if (row.source_from === 2) return 'Esyschooling';
                else if (row.source_from === 3) return 'Others';
                else return 'Unknown';
            },
            sortable: true,
            width: '150px',
        },

        {
            name: 'Created At',
            selector: row => {
                if (!row.createdAt) return 'N/A';
                const [year, month, day] = row.createdAt.split('T')[0].split('-');
                return `${day}-${month}-${year}`;
            },
            sortable: true,
            width: '150px',
        },
        {
            name: 'Error',
            selector: row => (
                <span title={row.error}>
                    {row.error === "null" || !row.error?.trim() ? '' : row.error}
                </span>
            ),
            sortable: true,
            width: '500px',
        }
    ], [pageIndex, pageSize]);

    const handlePageChange = (newPageIndex) => {
        setPageIndex(newPageIndex);
    };

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPageIndex(0);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleGenerateExcel = () => {
        const excelObjectData = {
            fileName: 'Error_Log',
            fileType: 'xlsx',
            sheetName: "Error_Log",
            firstRowMergeCells: "A1:H1",
            firstRowExportDate: new Date().toLocaleDateString(),
            secondRowMergeCells: "A2:H2",
            secondRowText: `Error Log Data Report`,
            headerEnglishColumns: ['S.No.', 'Name', 'Class', 'Phone Number', 'Email', 'Source', 'Created At', 'Error'],
            excelRowData: filteredData?.map((lead, index) => [
                index + 1 + pageIndex * pageSize,
                lead.name,
                lead.class,
                lead.phone_number,
                lead.email === "null" || !lead.email?.trim() ? '' : lead.email,
                lead.source_from === 1 ? 'Tablib' : lead.source_from === 2 ? 'Esyschooling' : 'Others',
                lead.createdAt ? formatDate(lead.createdAt.split('T')[0]) : 'N/A',
                lead.error === "null" || !lead.error?.trim() ? '' : lead.error,
            ])
        };

        generateReviewReport(excelObjectData);
    };

    const handleDateChange = (type, date) => {
        const parsedDate = new Date(date);
        if (parsedDate instanceof Date && !isNaN(parsedDate)) {

            if (type === "startDate") {
                formik.setFieldValue('startDate', parsedDate);
            }
            if (type === "endDate") {
                formik.setFieldValue('endDate', parsedDate);
            }
        }
    };

    useEffect(() => {
        if (formik.values.endDate && formik.values.startDate) {
            if (new Date(formik.values.startDate) < new Date(formik.values.endDate)) {
                formik.setErrors("endDate", "Change kar o ")
            } else {
                formik.setErrors("endDate", "")
            }
        }

    }, [formik.values.endDate, formik.values.startDate])

    const conditionalRowStyles = [
        {
            when: () => true,
            style: {
                backgroundColor: '', // Changed to solid red
                color: 'black',
            },
        },
    ];

    const handleReset = () => {
        formik.resetForm();
        setFilters({ startDate: null, endDate: null, selectedSource: '' });
        setGlobalFilter('');
        setPageIndex(0);
    };


    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="card mini-stats-wid">
                            <div className="card-body border rounded">
                                <div className="d-flex align-items-center gap-3">
                                    {/* Start Date Field */}
                                    <div className="mb-3" style={{ width: '20%' }}>
                                        <p className="text-muted fw-medium">File Upload Start Date</p>
                                        <input
                                            type="date"
                                            className="form-select"
                                            min="1960-01-01"
                                            max={new Date().toISOString().split("T")[0]} // Ensure max date is today
                                            value={formik.values.startDate ? formik.values.startDate.toISOString().split("T")[0] : ""}
                                            onChange={(e) => handleDateChange('startDate', new Date(e.target.value))}

                                        />
                                        {formik.errors.startDate && (
                                            <div className="text-danger">{formik.errors.startDate}</div>
                                        )}
                                    </div>

                                    {/* End Date Field */}
                                    <div className="mb-3" style={{ width: '20%' }}>
                                        <p className="text-muted fw-medium">File Upload End Date</p>
                                        <input
                                            type="date"
                                            className="form-select"
                                            // min={formik.values.startDate ? formik.values.startDate.toISOString().split("T")[0] : ""}
                                            //min={formik.values.startDate ? formik.values.startDate.toLocaleDateString('en-CA') : ""}
                                            // min="1960-01-01"
                                            max={new Date().toISOString().split("T")[0]}  // Ensure max date is today
                                            value={formik.values.endDate ? formik.values.endDate.toISOString().split("T")[0] : ""}
                                            onChange={(e) => { handleDateChange('endDate', new Date(e.target.value)) }}
                                        />
                                        {formik.errors.endDate ?
                                            <div className="text-danger">{formik.errors.endDate}</div>
                                            : <></>}
                                    </div>

                                    {/* Source Dropdown */}
                                    <div className="mb-3" style={{ width: '30%' }}>
                                        <p className="text-muted fw-medium">Source</p>
                                        <select
                                            className="form-select"
                                            value={formik.values.selectedSource}
                                            onChange={handleSourceChange}
                                        >
                                            <option value="">Select Source</option>
                                            <option value="1">Tablib</option>
                                            <option value="2">Esyschooling</option>
                                            <option value="3">Other</option>
                                        </select>
                                        {formik.errors.selectedSource && formik.touched.selectedSource && (
                                            <div className="text-danger">{formik.errors.selectedSource}</div>
                                        )}
                                    </div>

                                    {/* Search Button */}
                                    <div className="mb-3" style={{ width: 'auto', marginTop: '33px', marginLeft: '10px' }}>
                                        <button className="btn btn-primary" onClick={handleSearch} disabled={loading}>
                                            Search
                                        </button>
                                    </div>

                                    <div className="mb-3" style={{ width: 'auto', marginTop: '33px', marginLeft: '10px' }}>
                                        <button className="btn btn-primary" onClick={handleReset} disabled={loading}>
                                            Reset
                                        </button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    {errorLogData && (
                                        <>
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                <button
                                                    className="btn btn-primary text-decoration-none"
                                                    onClick={() => window.history.back()}
                                                    style={{ display: "flex", alignItems: "center", gap: "5px" }}
                                                >
                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                                    Back
                                                </button>
                                                <h4 className="card-title mb-4">Error Log Data: {filteredData?.length}</h4>
                                                <div className="d-flex justify-content-end mb-3">
                                                    <button onClick={handleGenerateExcel} className="btn btn-primary">
                                                        Download Excel
                                                    </button>
                                                </div>
                                            </div>
                                            <Pagination
                                                pageIndex={pageIndex}
                                                pageOptions={Array.from({ length: Math.ceil(filteredData?.length / pageSize) })}
                                                gotoPage={handlePageChange}
                                                previousPage={() => handlePageChange(pageIndex - 1)}
                                                nextPage={() => handlePageChange(pageIndex + 1)}
                                                canPreviousPage={pageIndex > 0}
                                                canNextPage={pageIndex < Math.ceil(filteredData?.length / pageSize) - 1}
                                                pageSize={pageSize}
                                                setPageSize={handlePageSizeChange}
                                                globalFilter={globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            >
                                                <DataTable
                                                    columns={columns}
                                                    data={filteredData?.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
                                                    pagination={false}
                                                    highlightOnHover
                                                    pointerOnHover
                                                    responsive
                                                    fixedHeader
                                                    conditionalRowStyles={conditionalRowStyles}
                                                />
                                            </Pagination>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorLogData;
