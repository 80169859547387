import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import useProfile from './useProfile';

const ChangePassword = () => {
  const { profileData } = useProfile();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (profileData) {
      formik.setFieldValue('email', profileData.email);
    }
  }, [profileData]);

  const formik = useFormik({
    initialValues: {
      email: '',
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .required('Current password is required'),
      password: Yup.string()
        .required('New password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character'
        )
        .max(30, 'Password cannot exceed 30 characters'),
      confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .max(30, 'Confirm password cannot exceed 30 characters'),
    }),
    onSubmit: async (values) => {
      const { email, oldPassword, password, confirmPassword } = values;

      const payload = { email, oldPassword, password, confirmPassword };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/app/profile/change-password`,
          payload
        );
        toast.success(response.data.message);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          const errorMessage = err.response.data.message;
          if (errorMessage.toLowerCase().includes('current password is incorrect')) {
            formik.setFieldError('oldPassword', 'Current password is incorrect!');
          } else {
            toast.error(errorMessage);
          }
        } else {
          toast.error('An error occurred. Please try again later.');
        }
      }
    },
  });

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="card">
          <div className="card-body pt-0">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <h2 className="mb-4">Change Password</h2>
                <form onSubmit={formik.handleSubmit}>
                  {/* Old Password Field */}
                  <div className="mb-3">
                    <label htmlFor="oldPassword" className="form-label">
                      Current Password:
                    </label>
                    <div className="input-group auth-pass-inputgroup">
                      <input
                        type={showOldPassword ? 'text' : 'password'}
                        id="oldPassword"
                        className="form-control"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} maxLength={30}
                      />
                      <button
                        className="btn btn-light"
                        type="button"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      >
                        <i className={`mdi ${showOldPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`} />
                      </button>
                    </div>
                    {formik.touched.oldPassword && formik.errors.oldPassword && (
                      <small className="text-danger">{formik.errors.oldPassword}</small>
                    )}
                  </div>

                  {/* New Password Field */}
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      New Password:
                    </label>
                    <div className="input-group auth-pass-inputgroup">
                      <input
                        type={showNewPassword ? 'text' : 'password'}
                        id="password"
                        className="form-control"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} maxLength={30}
                      />
                      <button
                        className="btn btn-light"
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        <i className={`mdi ${showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`} />
                      </button>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <small className="text-danger">{formik.errors.password}</small>
                    )}
                  </div>

                  {/* Confirm Password Field */}
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password:
                    </label>
                    <div className="input-group auth-pass-inputgroup">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        className="form-control"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} maxLength={30}
                      />
                      <button
                        className="btn btn-light"
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        <i className={`mdi ${showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`} />
                      </button>
                    </div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                      <small className="text-danger">{formik.errors.confirmPassword}</small>
                    )}
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;
