import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

const breakTitle = (title) => {
    return title;
};

export const generateReviewReport = async (excelObjectData) => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(excelObjectData.sheetName);

    //First Row Design and Merge cells A1:U1
    worksheet.mergeCells(excelObjectData.firstRowMergeCells);
    const firstHeaderRow = worksheet.getRow(1);
    firstHeaderRow.getCell(1).value = excelObjectData.firstRowExportDate
    firstHeaderRow.getCell(1).font = { name: 'SansSerif', size: 10 };
    firstHeaderRow.getCell(1).alignment = { horizontal: 'right', vertical: 'middle', wrapText: true };
    firstHeaderRow.height = 30;
    firstHeaderRow.getCell(1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };


    //Second Row Design and  Merge cells A2:U2
    worksheet.mergeCells(excelObjectData.secondRowMergeCells);
    const secondHeaderRow = worksheet.getRow(2);
    secondHeaderRow.getCell(1).value = excelObjectData.secondRowText;
    secondHeaderRow.getCell(1).font = { bold: true, name: 'SansSerif', size: 12 };
    secondHeaderRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    secondHeaderRow.height = 30;
    secondHeaderRow.getCell(1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
    };

    const headerRow1 = worksheet.addRow(excelObjectData.headerEnglishColumns);
    headerRow1.height = 55;
    headerRow1.eachCell((cell, colNumber) => {
        cell.font = { bold: true, name: 'SansSerif', size: 10 };
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
        cell.value = breakTitle(cell.value);
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        };
    
        const column = worksheet.getColumn(colNumber);
    
        // Set width for specific column D
        if (colNumber === 5) {
            column.width = 35;
        } else {
            column.width = 15; // Default width for other columns
        }
    });
    

    excelObjectData.excelRowData.forEach((row) => {
        const dataRow = worksheet.addRow(row);
        dataRow.height = 20;
        let isTotalRow = false;
        row.forEach((value, index) => {
            const cell = dataRow.getCell(index + 1);
            cell.font = { name: 'SansSerif', size: 10 };
            cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });
    });


    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    const fileName = `${excelObjectData.fileName}.${excelObjectData.fileType}`;
    FileSaver.saveAs(blob, fileName);
};
