import { useState, useEffect } from 'react';

const useProfile = () => {
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchProfileData = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) throw new Error('No token found');

            const myHeaders = new Headers();
            myHeaders.append("Authorization", token);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            const response = await fetch(`${process.env.REACT_APP_BASEURL}/app/user/profile-detail`, requestOptions);

            if (!response.ok) {
                const error = await response.text();
                throw new Error(error || 'Failed to fetch profile data');
            }

            const result = await response.json();
            setProfileData(result.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    return { profileData, loading, error, refetchProfileData: fetchProfileData };
};

export default useProfile;
