// // import React from 'react'
// // import MetisMenu from "@metismenu/react";
// // import SimpleBar from 'simplebar-react';
// // import { Link } from 'react-router-dom';

// // const SideBar = () => {
// //   return (
// //     <>
// //   <aside className="vertical-menu mm-active">
  
// //   <SimpleBar className="h-100 mm-show">


// //    <div className="sidebar-wrapper">
// //     <div data-simplebar="init" className="h-100 mm-show">     
// //       <div className='sidebar-wrapper'>
// //       <div id="sidebar-menu" className="mm-active">
         
// //         <MetisMenu className="metismenu list-unstyled" id="side-menu" >
// //           <li>
// //             <Link to="/" className="waves-effect active">           
// //               <i className="bx bxs-dashboard" />
// //               <span key="t-chat">Dashboard</span>
// //             </Link>
// //           </li>


// //           <li>         
// //             <Link to="javascript: void(0);" className="has-arrow waves-effect">
            
// //               <i className="bx bx-shield" /> <span key="t-layouts">Lead</span>
// //             </Link>
// //             <ul className="sub-menu" aria-expanded="false">
// //               <li>
// //                 <Link to="/lead-upload">Upload Lead</Link>
// //               </li>
// //               <li>
// //                 <Link to="/list-of-lead">List of Lead</Link>
// //               </li>
// //             </ul>
// //           </li>


// //         </MetisMenu>
// //       </div>
// //       </div>
    
// //     </div>
// //    </div>
// // </SimpleBar>

// // </aside>

// // </>

// //   )
// // }

// // export default SideBar

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import SimpleBar from 'simplebar-react';

// const SideBar = () => {
//   const [isLeadDropdownOpen, setLeadDropdownOpen] = useState(false);

//   const toggleLeadDropdown = () => {
//     setLeadDropdownOpen(!isLeadDropdownOpen);
//   };

//   return (
//     <aside className="vertical-menu mm-active">
//       <SimpleBar className="h-100 mm-show">
//         <div className="sidebar-wrapper">
//           <div id="sidebar-menu" className="mm-active">
//             <ul className="list-unstyled" id="side-menu">
//               <li>
//                 <Link to="/" className="waves-effect active">
//                   <i className="bx bxs-dashboard" />
//                   <span>Dashboard</span>
//                 </Link>
//               </li>
//               <li
//                 className={`dropdown ${isLeadDropdownOpen ? 'show' : ''}`}
//                 onClick={toggleLeadDropdown}
//               >
//                 <a
//                   href="#!"
//                   className="dropdown-toggle waves-effect"
//                   aria-expanded={isLeadDropdownOpen}
//                 >
//                   <i className="bx bx-shield" />
//                   <span>Lead</span>
//                 </a>
//                 <ul
//                   className={`dropdown-menu ${isLeadDropdownOpen ? 'show' : ''}`}
//                 >
//                   <li>
//                     <Link to="/lead-upload" className="dropdown-item">
//                       Upload Lead
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/list-of-lead" className="dropdown-item">
//                       List of Lead
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </SimpleBar>
//     </aside>
//   );
// };

// export default SideBar;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

const SideBar = () => {
  // State for managing the "Leads" and "Setting" dropdowns independently
  const [isLeadDropdownOpen, setLeadDropdownOpen] = useState(false);
  const [isSettingDropdownOpen, setSettingDropdownOpen] = useState(false);

  // Toggle function for the "Leads" dropdown
  const toggleLeadDropdown = () => {
    setLeadDropdownOpen(!isLeadDropdownOpen);
  };

  // Toggle function for the "Setting" dropdown
  const toggleSettingDropdown = () => {
    setSettingDropdownOpen(!isSettingDropdownOpen);
  };

  return (
    <aside className="vertical-menu mm-active">
      <SimpleBar className="h-100 mm-show">
        <div className="sidebar-wrapper">
          <div id="sidebar-menu" className="mm-active">
            <ul className="list-unstyled" id="side-menu">
              <li>
                <Link to="/" className="waves-effect active">
                  <i className="bx bxs-dashboard" />
                  <span>Dashboard</span>
                </Link>
              </li>
              {/* Leads Dropdown */}
              <li className="nav-item">
                <a
                  href="#"
                  className="has-arrow waves-effect"
                  onClick={toggleLeadDropdown}
                  aria-expanded={isLeadDropdownOpen}
                >
                  <i className="bx bx-shield" />
                  <span>Leads</span>
                </a>
                <ul
                  className={`sub-menu collapse ${isLeadDropdownOpen ? 'show' : ''}`}
                  id="leadDropdown"
                  aria-expanded={isLeadDropdownOpen}
                >
                  <li>
                    <Link to="/lead-upload">Upload Leads</Link>
                  </li>
                  <li>
                    <Link to="/list-of-lead">List of Leads</Link>
                  </li>
                </ul>
              </li>

              {/* Setting Dropdown */}
              <li className="nav-item">
                <a
                  href="#"
                  className="has-arrow waves-effect"
                  onClick={toggleSettingDropdown}
                  aria-expanded={isSettingDropdownOpen}
                >
                  <i className="bx bx-shield" />
                  <span>Setting</span>
                </a>
                <ul
                  className={`sub-menu collapse ${isSettingDropdownOpen ? 'show' : ''}`}
                  id="settingDropdown"
                  aria-expanded={isSettingDropdownOpen}
                >
                  <li>
                    <Link to="/my-profile">Profile</Link>
                  </li>
                  <li>
                    <Link to="/change-password">Change Password</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </SimpleBar>
    </aside>
  );
};

export default SideBar;
