import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

function LeadsBoard() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/get/leadData/sourceFrom`);
        const data = response.data.data.leadsData;      
        setTransactions(data); // Set the state with the fetched data
        setLoading(false);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-4">Sources and Leads</h4>
            <div className="table-responsive">
              <table className="table align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th className="align-middle">S.No</th>
                    <th className="align-middle">Source</th>
                    <th className="align-middle">Total Leads</th>
                    <th className="align-middle">Unique Leads</th>
                    <th className="align-middle">Duplicate Leads</th>
                    <th className="align-middle">View Details</th>
                    {/* <th className="align-middle">Payment Method</th>
                    <th className="align-middle">View Details</th> */}
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={transaction.id || index}>
                      <td>{index + 1}</td>
                      <td>{transaction.source_from}</td>
                      <td>{transaction.totalLeads}</td>
                      <td>{transaction.uniqueLeads}</td>
                      <td>{transaction.totalDuplicateLeads}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                          // onClick={() => navigate('/list-of-lead')}
                          onClick={() =>navigate("/list-of-lead", 
                            { state: { sourceFrom: transaction.id } })}
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* end table-responsive */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadsBoard;



