
import React from 'react'
const AuthLogo = () => {
    return (
        <div className="auth-logo">
            <a href="index.html" className="auth-logo-light">
                <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                        <img
                            src="assets/images/logo.png"
                            alt=""
                            className="rounded-circle"
                            height={70}
                        />
                    </span>
                </div>
            </a>
            <a href="index.html" className="auth-logo-dark">
                <div className="avatar-md profile-user-wid mb-2">
                    <span className="avatar-title rounded-circle bg-light">
                        <img
                            src="assets/images/logo.png"
                            alt=""
                            className="rounded-circle"
                            height={70}
                        />
                    </span>
                </div>
            </a>
        </div>
    )
}

export default AuthLogo

