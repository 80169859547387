import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const TotalDuplicates = ({ data, filters }) => {
  const [apiDuplicateLeads, setApiDuplicateLeads] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const propDuplicateLeads = data?.data?.totalDuplicateLeads
  const isDashboard = location.pathname === '/';
  useEffect(() => {
    if (isDashboard) {
      const fetchTotalLeads = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/dashboard/duplicate/leads`);
          setApiDuplicateLeads(response?.data?.data?.totalDuplicateLeads || 0);
        } catch (error) {
          console.error('Error fetching total leads:', error);
          setApiDuplicateLeads(0);
        }
      };

      fetchTotalLeads();
    }
  }, [isDashboard]);

  const duplicateLeads = isDashboard ? apiDuplicateLeads : propDuplicateLeads;

  // const handleCardClick = () => {
  //   navigate('/duplicate-leads');
  // };
  const handleCardClick = () => {
    if (duplicateLeads > 0) {
      navigate('/duplicate-leads', { state: { ...filters } });
    } else {
      console.warn('No data available to navigate to /total-leads');
    }
  };

  return (
   
      <div className="card mini-stats-wid" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
        <div className="card-body border rounded">
          <div className="d-flex">
            <div className="flex-grow-1">
              <p className="text-muted fw-medium">Total Duplicates</p>
              <h4 className="mb-0">{duplicateLeads !== null ? duplicateLeads : 'Loading...'}</h4>
            </div>
            <div className="flex-shrink-0 align-self-center">
              <div className="avatar-sm rounded-circle bg-primary mini-stat-icon">
                <span className="avatar-title">
                  <i className="bx bx-copy-alt font-size-24" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
   

  )
}

export default TotalDuplicates