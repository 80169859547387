import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const UniqueLeads = ({ data }) => {
  const [apiUniqueLeads, setApiUniqueLeads] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const propUniqueLeads = data?.data?.uniqueLead;
  const isDashboard = location.pathname === '/';
  useEffect(() => {
    if (isDashboard) {
      const fetchTotalLeads = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/dashboard/unique/leads`);
          setApiUniqueLeads(response?.data?.data?.totalUniqueLeads || 0);
        } catch (error) {
          console.error('Error fetching total leads:', error);
          setApiUniqueLeads(0);
        }
      };

      fetchTotalLeads();
    }
  }, [isDashboard]);

  const uniqueLeads = isDashboard ? apiUniqueLeads : propUniqueLeads;

  // const handleCardClick = () => {
  //   navigate('/unique-leads');
  // };
  // const handleCardClick = () => {
  //   if (uniqueLeads > 0) {
  //     navigate('/unique-leads');
  //   } else {
  //     console.warn('No data available to navigate to /total-leads');
  //   }
  // };

  return (
    
      <div className="card mini-stats-wid"
        //  onClick={handleCardClick}
        style={{ cursor: 'pointer' }}>
        <div className="card-body border rounded">
          <div className="d-flex">
            <div className="flex-grow-1">
              <p className="text-muted fw-medium">Unique Leads</p>
              <h4 className="mb-0">{uniqueLeads !== null ? uniqueLeads : 'Loading...'}</h4>
            </div>
            <div className="flex-shrink-0 align-self-center">
              <div className="avatar-sm rounded-circle bg-primary mini-stat-icon" >
                <span className="avatar-title" style={{ background: "green" }}>
                  <i className="bx bx-diamond font-size-24" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
   

  );
};

export default UniqueLeads;

