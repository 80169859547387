import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ErrorLog = () => {
    const navigate = useNavigate();
    const [errorLog, setErrorLog] = useState(null);
    const [errorLogData, setErrorLogData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}/app/get/error/log`)
            .then(response => {
                setErrorLog(response.data.data.totalErrorLogData);
                setErrorLogData(response.data.data.errorLog)
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false);
            });
    }, []);


    const handleCardClick = () => {
        navigate('/error-log', { state: { errorLogData } });
    };
    return (
     
            <div className="card mini-stats-wid" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
                <div className="card-body border rounded">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Error Log</p>
                            <h4 className="mb-0">{errorLog}</h4>
                        </div>
                        <div className="flex-shrink-0 align-self-center">
                            <div className="avatar-sm rounded-circle bg-primary mini-stat-icon " >
                                <span className="avatar-title " style={{background:"red"}}>
                                <i className="bx bx-error font-size-24" /> 
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    );
}

export default ErrorLog;

