import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      setError('Invalid or missing token.');
      toast.error('Invalid or missing token.');
    }
  }, [token]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character.'
        ),
      confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const payload = {
        token,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/app/reset/password`,
          payload,
          { headers: { 'Content-Type': 'application/json' } }
        );

        if (response.data.message) {
          toast.success('Password has been reset successfully.', {
            position: 'top-right',
            autoClose: 2000,
          });
          setTimeout(() => navigate('/login'), 2000);
        }
      } catch (err) {
        setError(err.response?.data?.message || 'Something went wrong. Please try again.');
        toast.error(err.response?.data?.message || 'Something went wrong. Please try again.');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden">
              <div className="bg-primary bg-soft">
                <div className="row">
                  <div className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Reset Password</h5>
                      <p>Reset your password securely.</p>
                    </div>
                  </div>
                  <div className="col-5 align-self-end">
                    <img
                      src="assets/images/profile-img.png"
                      alt="profile"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <div>
                  <a href="index.html">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src="assets/images/logo.png"
                          alt="logo"
                          className="rounded-circle"
                          height={74}
                        />
                      </span>
                    </div>
                  </a>
                </div>
                <div className="p-2">
                  <form className="needs-validation" onSubmit={formik.handleSubmit} noValidate>
                    {/* Password Field */}
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password <span>*</span>
                      </label>
                      <div className="input-group auth-pass-inputgroup">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                          id="password"
                          placeholder="Enter password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          maxLength={30}
                        />
                        <button
                          className="btn btn-light"
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i className={`mdi ${showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`} />
                        </button>
                      </div>
                      {formik.touched.password && formik.errors.password && (
                        <div className="text-danger">{formik.errors.password}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password <span>*</span>
                      </label>
                      <div className="input-group auth-pass-inputgroup">
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'is-invalid' : ''}`}
                          id="confirmPassword"
                          placeholder="Confirm password"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          maxLength={30}
                        />
                        <button
                          className="btn btn-light"
                          type="button"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          <i className={`mdi ${showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`} />
                        </button>
                      </div>
                      {/* Show error below the field */}
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className="text-danger">{formik.errors.confirmPassword}</div>
                      )}
                    </div>

                    {/* Error Message */}
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}

                    {/* Submit Button */}
                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? 'Resetting...' : 'Reset Password'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p>
                Remember It?{' '}
                <Link to="/login" className="fw-medium text-primary">
                  Sign In here
                </Link>
              </p>
              <p>
                © SRMPS.<i className="mdi mdi-heart text-danger" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
