import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Your component imports
import Aside from './components/aside/Aside';
import Dashboard from './components/dashboard/Dashboard';
import LeadUpload from './components/leads/LeadUpload';
import LeadList from './components/leads/LeadList';
import Allleads from './components/leads/Allleads';
import TotalUniqueLeads from './components/leads/TotalUniqueLeads';
import AllDuplicateLeads from './components/leads/AllDuplicateLeads';
import ChangePassword from './components/settings/ChangePassword';
import MyProfile from './components/settings/MyProfile';
import Login from './pages/login/Login';
import useAuth from './hooks/useAuth';
import SignUp from './pages/signup/SignUp';
import ForgetPassword from './pages/forgetPassword/ForgetPassword';
import ResetPassword from './pages/resetPassword.jsx/ResetPassword';
import ErrorLog from './components/leads/ErrorLog';
import ErrorLogData from './components/leads/ErrorLogData';

const App = () => {
  const { isAuthenticated, loading } = useAuth();
  // const { isAuthenticated, loading, serverDown } = useAuth();


  if (loading) {
    return <div>Loading...</div>;
  }


  // if (serverDown) {
  //   return (
  //     <div style={{ textAlign: "center", marginTop: "20%" }}>
  //       <h1>Server Down</h1>
  //       <p>We are unable to connect to the server. Please try again later.</p>
  //       <button onClick={() => window.location.reload()}>Retry</button>
  //     </div>
  //   );
  // }

  return (
    <Router>
      {isAuthenticated && <Aside />}
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/lead-upload" element={<LeadUpload />} />
            <Route path="/list-of-lead" element={<LeadList />} />
            <Route path="/total-leads" element={<Allleads />} />
            <Route path="/unique-leads" element={<TotalUniqueLeads />} />
            <Route path="/duplicate-leads" element={<AllDuplicateLeads />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/error-log" element={<ErrorLogData />} />

          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} /> // Redirect unauthenticated users
        )}

        {/* Public Routes */}
        {!isAuthenticated && (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </>
        )}

        {/* Default Route */}
        <Route
          path="*"
          element={<Navigate to={isAuthenticated ? "/" : "/login"} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
