import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthLogo from '../../components/sharedComponent/AuthLogo';

const Login = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is required'),
      password: Yup.string()
        .required('Password is required')
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
        //   'Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character'
        // ),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/app/login`, values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.data.data.accessToken) {
          localStorage.setItem('accessToken', response.data.data.accessToken);
          window.location.href = '/';
        } else {
          throw new Error('Token not found in response');
        }
      } catch (err) {
        if (err.response) {
          const errorMessage = err.response.data.message || 'Login failed';
          if (err.response.data.fieldErrors) {
            setErrors({
              email: err.response.data.fieldErrors.email || '',
              password: err.response.data.fieldErrors.password || '',
              general: errorMessage,
            });
              
          } else {           
            setErrors({ general: errorMessage });
          }
        } else {
         
          setErrors({ general: err.message || 'An error occurred' });
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-primary bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to SRMPS.</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img
                        src="assets/images/profile-img.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                <AuthLogo />
                  <div className="p-2">
                    <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={120}
                        />
                        {/* Show error below the field */}
                        {formik.touched.email && formik.errors.email && (
                          <small className="text-danger">{formik.errors.email}</small>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password <span>*</span></label>
                        <div className="input-group auth-pass-inputgroup">
                          <input
                            type={formik.values.showPassword ? 'text' : 'password'}
                            className="form-control"
                            name="password"
                            placeholder="Enter password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={20}
                          />
                          <button
                            className="btn btn-light"
                            type="button"
                            id="password-addon"
                            onClick={() => formik.setFieldValue('showPassword', !formik.values.showPassword)}
                          >
                            <i className={`mdi ${formik.values.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`} />
                          </button>
                        </div>
                        {/* Show error below the field */}
                        {formik.touched.password && formik.errors.password && (
                          <small className="text-danger">{formik.errors.password}</small>
                        )}
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          {formik.isSubmitting ? 'Logging In...' : 'Log In'}
                        </button>
                      </div>
                      {formik.errors.general && (
                        <div className="mt-3 text-danger text-center">
                          {formik.errors.general} {/* Display general error */}
                        </div>
                      )}

                      <div className="mt-4 text-center">
                        <Link to="/forget-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your password?
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <div>
                  <p>
                    Don't have an account ?{' '}
                    <Link to="/signup" className="fw-medium text-primary">
                      Signup now
                    </Link>
                  </p>
                  <p>
                    © SRMPS.<i className="mdi mdi-heart text-danger" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
