

import { useState, useEffect } from 'react';

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginInfo, setLoginInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const validateToken = (token) => {
    // Implement token validation logic (e.g., expiration check, format validation)
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT token payload
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decodedToken.exp > currentTime; // Ensure token is not expired
    } catch (error) {
      return false; // Return false if token is invalid
    }
  };

  const loginCallFromChild = (token) => {
    if (!validateToken(token)) {
      logout();
      return;
    }

    localStorage.setItem("accessToken", token);
    setIsAuthenticated(true);
    setLoginInfo({ token });
  };

  const logout = () => {
    setIsAuthenticated(false);
    setLoginInfo(null);
    localStorage.removeItem('accessToken');
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token && validateToken(token)) {
      loginCallFromChild(token);
    } else {
      logout();
    }

    setLoading(false);

    const handleStorageChange = (event) => {
      if (event.key === "accessToken" && event.newValue === null) {
        logout();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return {
    isAuthenticated,
    loginInfo,
    loginCallFromChild,
    logout,
    loading,
  };
}

export default useAuth;
