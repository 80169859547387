import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MyProfile from '../settings/MyProfile';
import useProfile from '../settings/useProfile';

const Profile = () => {
  const { profileData, loading, error } = useProfile();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="card overflow-hidden">
      <div className="bg-primary bg-soft">
        <div className="row">
          <div className="col-7">
            <div className="text-primary p-3">
              <h5 className="text-primary">Welcome Back !</h5>
              <p>SRMPS</p>
            </div>
          </div>
          <div className="col-5 align-self-end">
            <img
              src="assets/images/profile-img.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-sm-4">
            <div className="avatar-md profile-user-wid mb-4">
              <img
                src="assets/images/users/avatar.jpg"
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
            <h5 className="font-size-15" style={{ whiteSpace: "nowrap" }}>Name: {profileData?.userName}</h5>
            <p className="text-muted mb-0" style={{ whiteSpace: "nowrap" }}>Email: {profileData?.email}</p>
          </div>
         
        </div>

      </div>
    </div>
  );
};

export default Profile;
