
import React, { useEffect, useState } from 'react'
import { useNavigate} from 'react-router-dom';
import Profile from './Profile'
import SocialSource from './SocialSource'
import Activity from './Activity'
import LeadsBoard from './LeadsBoard'
import MonthlyEarning from './MonthlyEarning'
import EmailSent from './EmailSent'
import TotalLeads from './TotalLeads'
import UniqueLeads from './UniqueLeads'
import TotalDuplicates from './TotalDuplicates'
import useFetchLeads from '../leads/useFetchLeads'
import HighestLeads from './HighestLeads'
import CellingProduct from './CellingProduct'
import ErrorLog from '../leads/ErrorLog'
const Dashboard = () => {
  const navigate = useNavigate();

  // const { data } = useLeads();  // Use the hook
  // const fetchLeads = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/get/leads`);
  //     const resData = response.data;


  //     setData(resData);

  //   } catch (error) {
  //     console.error('Error fetching leads:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchLeads();
  // }, []);

  const { data } = useFetchLeads();

  const handleCardClick = () => {
    navigate('/unique-leads');
  };



  return (
    <div className='main-content'>
      <div className="page-content">
        <div className="container-fluid">
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}

          <div className="row">
            <div className="col-xl-4">
              <Profile />
              <HighestLeads />
              {/* <MonthlyEarning /> */}
              {/* <Price/> */}
            </div>
            <div className="col-xl-8">
              <div className="row">
                <div className="col-md-3">
                  <TotalLeads data={data} />
                </div>
                <div className="col-md-3" onClick={handleCardClick}>
                  <UniqueLeads data={data} />
                </div>
                <div className="col-md-3">
                  <TotalDuplicates data={data} />
                </div>
                <div className="col-md-3">
                  <ErrorLog />
                </div>

              </div>
              <LeadsBoard />
              {/* <EmailSent/> */}
            </div>
          </div>

          {/* <div className="row">
            <SocialSource />
            <Activity />
            <CellingProduct />
          </div> */}
          {/* <LeadsBoard /> */}
        </div>
      </div>

    </div>
  )
}

export default Dashboard