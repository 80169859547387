import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ForgetPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const payload = { email: values.email };

      try {
        const apiUrl = `${process.env.REACT_APP_BASEURL}/app/forgot/password/`; // API URL
        const response = await axios.post(apiUrl, payload, {
          headers: {
            'Content-Type': 'application/json', // Ensure you're sending JSON
          },
        });

        if (response.status === 200) {
          toast.success('Password reset email sent successfully');
        } else {
          toast.error('User does not exist');
        }
      } catch (error) {
        if (error.response) {
          toast.error(`${error.response.data.message || 'Unknown error'}`);
        } else if (error.request) {
          toast.error('No response from server.');
        } else {
          toast.error(`${error.message}`);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <ToastContainer /> {/* Toast notification container */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden">
              <div className="bg-primary bg-soft">
                <div className="row">
                  <div className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Reset Password</h5>
                      <p>Reset Password with Skote.</p>
                    </div>
                  </div>
                  <div className="col-5 align-self-end">
                    <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <div>
                  <a href="index.html">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src="assets/images/logo.png" alt="" className="rounded-circle" height={70} />
                      </span>
                    </div>
                  </a>
                </div>
                <div className="p-2">
                  <form onSubmit={formik.handleSubmit} className="form-horizontal">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email <span>*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`} // Apply the invalid class if the email is invalid
                        id="email"
                        placeholder="Enter email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} maxLength={50}
                      />
                      {/* Show error message below the input */}
                      {formik.touched.email && formik.errors.email && (
                        <div className="invalid-feedback">{formik.errors.email}</div>
                      )}
                    </div>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary w-md waves-effect waves-light"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? 'Sending...' : 'Reset'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p>
                Remember it?{' '}
                <Link to="/login" className="fw-medium text-primary">
                  Sign In here
                </Link>
              </p>
              <p>
                © SRMPS.<i className="mdi mdi-heart text-danger" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
