import React from 'react'

function CellingProduct() {
  return (
    <div className="col-xl-4">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4">Highest Lead Source</h4>
          <div className="text-center">
            <div className="mb-4">
              {" "}
              <i className="bx bx-map-pin text-primary display-4" />{" "}
            </div>
            <h3>1,456</h3>
            <p>San Francisco</p>
          </div>
          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Tablib</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">1,456</h5>
                  </td>
                  <td>
                    <div className="progress bg-transparent progress-sm">
                      <div
                        className="progress-bar bg-primary rounded"
                        role="progressbar"
                        style={{ width: "94%" }}
                        aria-valuenow={94}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Esyschooling</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,123</h5>
                  </td>
                  <td>
                    <div className="progress bg-transparent progress-sm">
                      <div
                        className="progress-bar bg-success rounded"
                        role="progressbar"
                        style={{ width: "82%" }}
                        aria-valuenow={82}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Others</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,026</h5>
                  </td>
                  <td>
                    <div className="progress bg-transparent progress-sm">
                      <div
                        className="progress-bar bg-warning rounded"
                        role="progressbar"
                        style={{ width: "70%" }}
                        aria-valuenow={70}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  )
}

export default CellingProduct