import React from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import { Outlet } from "react-router-dom";

const Aside = () => {
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <SideBar />
        <Outlet />
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2024 © STPL </div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                {" "}
                Design &amp; Develop by STPL{" "}
              </div>
            </div>
          </div>
        </div>
      </footer>
      </>
  );
};

export default Aside;
