import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const TotalLeads = ({ data,filters }) => {  
  const [apiTotalLeads, setApiTotalLeads] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const propTotalLeads = data?.data?.totalLeads;
  const isDashboard = location.pathname === '/';

  useEffect(() => {
    if (isDashboard) {
      const fetchTotalLeads = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/dashboard/all/leads`);
          setApiTotalLeads(response?.data?.data?.totalLeads || 0);
        } catch (error) {
          console.error('Error fetching total leads:', error);
          setApiTotalLeads(0);
        }
      };

      fetchTotalLeads();
    }
  }, [isDashboard]);

  const totalLeads = isDashboard ? apiTotalLeads : propTotalLeads;

  const handleCardClick = () => {
    if (totalLeads > 0) {
      navigate('/total-leads', { state: { ...filters } });
    } else {
      console.warn('No data available to navigate to /total-leads');
    }
  };

  return (
   
      <div className="card mini-stats-wid" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
        <div className="card-body border rounded">
          <div className="d-flex">
            <div className="flex-grow-1">
              <p className="text-muted fw-medium">Total Leads</p>
              <h4 className="mb-0">{totalLeads !== null ? totalLeads : 'Loading...'}</h4>
            </div>
            <div className="flex-shrink-0 align-self-center">
              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                <span className="avatar-title" style={{background:"orange"}}>
                  <i className="bx bx-list-ul font-size-24" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
   
   
  );
};

export default TotalLeads
