import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useProfile from '../settings/useProfile';

const Header = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const { profileData, refetchProfileData, loading } = useProfile();

    const handleLogout = () => {
        logout();
        navigate('/');
        window.location.reload();
    };

    useEffect(() => {
        refetchProfileData();
    }, []);

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    {/* LOGO */}
                    <div className="navbar-brand-box">
                        <Link to="/" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src="assets/images/logo_sm.png" title="SRMPS" alt="Logo" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/logo.webp" title="SRMPS" alt="Logo" />
                            </span>
                        </Link>
                    </div>
                    <button
                        type="button"
                        className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                        id="vertical-menu-btn">
                        <i className="fa fa-fw fa-bars" />
                    </button>
                </div>
                <div className="d-flex">
              
                    <div className="dropdown d-inline-block">



                        <button
                            type="button"
                            className="btn header-item waves-effect"
                            id="page-header-user-dropdown"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">



                            <img
                                className="rounded-circle header-profile-user"
                                src="assets/images/users/avatar.jpg"
                                alt="Header Avatar"
                            />
                            <span className="d-none d-xl-inline-block ms-1">
                                {loading ? 'Loading...' : profileData?.userName}
                            </span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                            <div className="dropdown-divider" />
                            <Link
                                to="/my-profile"  // Add this line for the profile page
                                className="dropdown-item"
                            >
                                <i className="bx bx-user font-size-16 align-middle me-1" />
                                <span>My Profile</span>
                            </Link>
                            <div className="dropdown-divider" />
                            <Link
                                to="/change-password"  // Add this line for the profile page
                                className="dropdown-item"
                            >
                                <i className="bx bx-user font-size-16 align-middle me-1" />
                                <span>Change Password</span>
                            </Link>
                            <div className="dropdown-divider" />
                            <Link
                                to="#"
                                className="dropdown-item text-danger"
                                onClick={handleLogout}
                            >
                                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                                <span>Logout</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
