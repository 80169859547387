import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import AuthLogo from '../../components/sharedComponent/AuthLogo';

const SignUp = () => {
  const navigate = useNavigate();

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: '',
      userName: '',
      password: '',
      showPassword: false, // Used for password visibility toggle
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email is required'),
      userName: Yup.string().required('Username is required'),
      password: Yup.string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character'
        ),
    }),
    onSubmit: async (values) => {
      const { email, userName, password } = values;

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/app/sign-up`, {
          email,
          userName,
          password,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.data) {
          toast.success('Registered successfully!', {
            position: 'top-right',
            autoClose: 2000,
          });

          setTimeout(() => navigate('/login'), 2000);
        }
      } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong. Please try again.');
      }
    },
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden">
              <div className="bg-primary bg-soft">
                <div className="row">
                  <div className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Free Register</h5>
                      <p>Create your account now.</p>
                    </div>
                  </div>
                  <div className="col-5 align-self-end">
                    <img
                      src="assets/images/profile-img.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <AuthLogo />
                <span className="text-primary">Password must be at least 8 characters long, contain an uppercase and lowercase letter, a number, and a special character.</span>
                  <div className="p-4">
                  <form onSubmit={formik.handleSubmit}>
                    {/* Email Field */}
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter email"
                        maxLength={50}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <small className="text-danger">{formik.errors.email}</small>
                      )}
                    </div>

                    {/* Username Field */}
                    <div className="mb-3">
                      <label htmlFor="userName" className="form-label">User Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        name="userName"
                        value={formik.values.userName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter username"
                        maxLength={120}
                      />
                      {formik.touched.userName && formik.errors.userName && (
                        <small className="text-danger">{formik.errors.userName}</small>
                      )}
                    </div>

                    {/* Password Field */}
                    <div className="mb-3">
                      <label className="form-label">Password</label>
                      <div className="input-group auth-pass-inputgroup">
                        <input
                          type={formik.values.showPassword ? 'text' : 'password'}
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder="Enter password"
                          aria-label="Password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={20}
                        />
                        <button
                          className="btn btn-light"
                          type="button"
                          onClick={() => formik.setFieldValue('showPassword', !formik.values.showPassword)}
                        >
                          <i className={`mdi ${formik.values.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'}`} />
                        </button>
                      </div>
                      {formik.touched.password && formik.errors.password && (
                        <small className="text-danger">{formik.errors.password}</small>
                      )}
                    </div>

                    {/* Submit Button */}
                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        {formik.isSubmitting ? 'Registering...' : 'Register'}
                      </button>
                    </div>

                    {/* General Error Message */}
                    {formik.errors.general && (
                      <div className="mt-3 text-danger text-center">
                        {formik.errors.general}
                      </div>
                    )}

                    {/* Sign In Link */}
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Already have an account?{' '}
                        <a href="/login" className="text-primary fw-medium">
                          Sign In
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p>© SRMPS.<i className="mdi mdi-heart text-danger" /> </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
