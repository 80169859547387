import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const useFetchLeads = (startDate, endDate, selectedSource) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);

      let apiUrl = `${process.env.REACT_APP_BASEURL}/app/get/leads`; // Base API URL

      const finalSource = selectedSource || '';  // Default source to '' if not selected

      if (startDate && endDate) {
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        apiUrl = `${apiUrl}?startDate=${formattedStartDate}&endDate=${formattedEndDate}&source_from=${finalSource}`;
      } else {
        apiUrl = `${apiUrl}?source_from=${finalSource}`;
      }

      try {
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching leads:', error);
        toast.error('Failed to fetch leads');
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, [startDate, endDate, selectedSource]); // Dependencies updated to react to prop changes

  return { data, loading };
};

export default useFetchLeads;
