// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { GlobalLoading, showLoading } from "react-global-loading";
// import { useNavigate } from 'react-router-dom';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import ErrorLog from './ErrorLog';

// const LeadUpload = () => {
//   const [sources, setSources] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [errorMessage, setErrorMessage] = useState('');
//   const navigate = useNavigate();



//   useEffect(() => {
//     const fetchSources = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/get/lead/source`);
//         if (response.status === 200) {
//           const resData = response.data.data;
//           const sourceArray = resData.map(item => ({ id: item.id, source_from: item.source_from }));
//           setSources(sourceArray);
//         } else {
//           setErrorMessage('Failed to load sources.');
//         }
//       } catch (error) {
//         setErrorMessage('Error fetching sources: ' + error.message);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchSources();
//   }, []);

//   // Formik Setup
//   const formik = useFormik({
//     initialValues: {
//       source: '',
//       file: null,
//     },
//     validationSchema: Yup.object({
//       source: Yup.string().required('Please select a source.'),
//       file: Yup.mixed().required('Please upload a file.')
//         .test('fileFormat', 'File must be in .xls or .xlsx format', value => {
//           if (!value) return true;
//           const fileExtension = value.name.split('.').pop().toLowerCase();
//           return fileExtension === 'xls' || fileExtension === 'xlsx';
//         }),
//     }),
//     onSubmit: async (values, { setSubmitting, setErrors }) => {
//       setSubmitting(true);
//       try {
//         const formData = new FormData();
//         formData.append('file', values.file);
//         formData.append('source_from', values.source);

//         showLoading(true);
//         const response = await axios.post(`${process.env.REACT_APP_BASEURL}/app/upload/`, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });

//         if (response.status === 200) {
//           toast.success('File uploaded successfully!');
//           setTimeout(() => {
//             navigate('/list-of-lead');
//           }, 1000);
//         } else {
//           toast.error('File upload failed. Please try again.');
//         }
//       } catch (error) {
//         if (error.response && error.response.data) {
//           const errorMessage = error.response.data.message || 'An error occurred while uploading the file.';
//           const invalidData = error.response.data.invalidData;

//           toast.error(`${errorMessage}`);

//           // if (invalidData && invalidData.length > 0) {
//           //   invalidData.forEach((data) => {
//           //     const row = data.row.join(', ') || 'Unknown row data';
//           //     const errorDetail = data.error || 'Unknown error';
//           //     toast.error(`Row: ${row}, Error: ${errorDetail}`);
//           //   });
//           // }
//         } else {
//           toast.error('An unexpected error occurred. Please try again.');
//         }
//       } finally {
//         setSubmitting(false);
//         showLoading(false);
//       }
//     },
//   });

//   return (
//     <div className='main-content'>
//       <div className="page-content">
//         <GlobalLoading />
//         <ToastContainer />
//         <div className="container-fluid">


//         <div className="row">
//             <div className="col-lg-12">
//               <div className="card">
//                 <div className="card-body">
//                   <div className="d-flex justify-content-between align-items-center mb-4"> 
//                     <h4 className="mb-0 font-size-18 text-primary">Sample Format of Leads <i className="fa fa-info-circle mr-1" aria-hidden="true" style={{ fontSize: '18px' }}></i><small className="p-2">(Please verify your excel sheet data there should be like wise grid before upload).</small></h4>
//                     <a href="/template.xlsx" download>
//                       <i className="fas fa-file-excel" style={{ fontSize: '24px' }} />
//                       <span className="m-2 fs-5">Download Sample Template</span>
//                     </a>
//                   </div>
//                   <div className="table-responsive">
//                     <table className="table align-middle table-nowrap mb-0">
//                       <thead className="table-light">
//                         <tr>
//                           <th>Timestamp (YYYY-MM-DD HH:MM:SS)</th>
//                           <th>Name</th>
//                           <th>Class</th>
//                           <th>Phone Number (10 digit)</th>
//                           <th>Email</th>
//                           <th>Remarks</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {/* Static Data */}
//                         {/* <tr>
//                           <td>YYYY-MM-DD HH:MM:SS</td>
//                           <td>Name</td>
//                           <td>Class</td>
//                           <td>10 digit number</td>
//                           <td>Test@gmail.com</td>
//                           <td>remarks</td>
//                         </tr> */}
//                         <tr>
//                           <td>2023-03-02 18:53:54</td>
//                           <td>Naveen</td>
//                           <td>Class 11</td>
//                           <td>8400548232</td>
//                           <td>1260naveen@gmail.com</td>
//                           <td>remarks</td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>


//           <div className="row">
//             <div className="col-12">
//               <div className="page-title-box d-sm-flex align-items-center justify-content-between">
//                 <h4 className="mb-sm-0 font-size-18">Excelsheet upload for leads</h4>
//                 {/* <a href="/template.xlsx" download>
//                   <i className="fas fa-file-excel" style={{ 'fontSize': '24px' }} />
//                   <span className="m-2 fs-4">Download Sample Template</span>
//                 </a> */}
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-lg-8">
//               <div className="card">
//                 <div className="card-body">
//                   <form onSubmit={formik.handleSubmit}>
//                     <div className="row">
//                       <div className="col-md-5 mb-3">
//                         <label htmlFor="source" className="form-label">Source From</label>
//                         {loading ? (
//                           <p>Loading sources...</p>
//                         ) : errorMessage ? (
//                           <p className="text-danger">{errorMessage}</p>
//                         ) : (
//                           <select
//                             id="source"
//                             className="form-select"
//                             value={formik.values.source}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             name="source"
//                           >
//                             <option value="">Select Source</option>
//                             {sources.map((item) => (
//                               <option key={item.id} value={item.id}>
//                                 {item.source_from}
//                               </option>
//                             ))}
//                           </select>
//                         )}
//                         {formik.touched.source && formik.errors.source && (
//                           <small className="text-danger">{formik.errors.source}</small>
//                         )}
//                       </div>

//                       <div className="col-md-5 mb-3">
//                         <label htmlFor="file" className="form-label">Upload File</label>
//                         <input
//                           type="file"
//                           className="form-control"
//                           id="file"
//                           name="file"
//                           accept=".xls,.xlsx"
//                           onChange={(event) => formik.setFieldValue("file", event.currentTarget.files[0])}
//                           onBlur={formik.handleBlur}
//                         />
//                         {formik.touched.file && formik.errors.file && (
//                           <small className="text-danger">{formik.errors.file}</small>
//                         )}
//                       </div>

//                       <div className="col-md-2 mt-4 mb-3">
//                       <label htmlFor="file" className="form-label"></label>
//                       <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
//                       {formik.isSubmitting ? 'Uploading...' : 'Submit'}
//                     </button>
//                       </div>
//                     </div>


//                   </form>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-4">
//             <ErrorLog />
//             </div>
//           </div>




//         </div>
//       </div>
//     </div>
//   );
// };

// export default LeadUpload;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GlobalLoading, showLoading } from "react-global-loading";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';  // Import SweetAlert2
import ErrorLog from './ErrorLog';

const LeadUpload = () => {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [refreshErrorLog, setRefreshErrorLog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/get/lead/source`);
        if (response.status === 200) {
          const resData = response.data.data;
          const sourceArray = resData.map(item => ({ id: item.id, source_from: item.source_from }));
          setSources(sourceArray);
        } else {
          setErrorMessage('Failed to load sources.');
        }
      } catch (error) {
        setErrorMessage('Error fetching sources: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchSources();
  }, []);

  // Formik Setup
  const formik = useFormik({
    initialValues: {
      source: '',
      file: null,
    },
    validationSchema: Yup.object({
      source: Yup.string().required('Please select a source.'),
      file: Yup.mixed().required('Please upload a file.')
        .test('fileFormat', 'File must be in .xls or .xlsx format', value => {
          if (!value) return true;
          const fileExtension = value.name.split('.').pop().toLowerCase();
          return fileExtension === 'xls' || fileExtension === 'xlsx';
        }),
    }),
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      setSubmitting(true);
      try {
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('source_from', values.source);

        showLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/app/upload/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: 'Success!',
            text: 'File uploaded successfully!',
            icon: 'success',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            customClass: {
              cancelButton: 'btn btn-danger'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/list-of-lead');
            } else {
              setRefreshErrorLog((prev) => !prev);
            }
          });
          resetForm();
          document.getElementById('file').value = '';
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'File upload failed. Please try again.',
            icon: 'error',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel'
          });
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message || 'An error occurred while uploading the file.';
          const invalidData = error.response.data.invalidData;

          Swal.fire({
            title: 'Error!',
            text: `${errorMessage}`,
            icon: 'error',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel'
          });

        } else {
          Swal.fire({
            title: 'Error!',
            text: 'An unexpected error occurred. Please try again.',
            icon: 'error',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel'
          });
        }
      } finally {
        setSubmitting(false);
        showLoading(false);
      }
    },
  });

  return (
    <div className='main-content'>
      <div className="page-content">
        <GlobalLoading />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="mb-0 font-size-18 text-primary">Sample Format of Leads <i className="fa fa-info-circle mr-1" aria-hidden="true" style={{ fontSize: '18px' }}></i><small className="p-2">(Please verify your excel sheet data there should be like wise grid before upload).</small></h4>
                    <a href="/template.xlsx" download>
                      <i className="fas fa-file-excel" style={{ fontSize: '24px' }} />
                      <span className="m-2 fs-5">Download Sample Template</span>
                    </a>
                  </div>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>Timestamp (YYYY-MM-DD HH:MM:SS)</th>
                          <th>Name</th>
                          <th>Class</th>
                          <th>Phone Number (10 digit)</th>
                          <th>Email</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>2023-03-02 18:53:54</td>
                          <td>Naveen</td>
                          <td>Class 11</td>
                          <td>8400548232</td>
                          <td>1260naveen@gmail.com</td>
                          <td>remarks</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Excelsheet upload for leads</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-5 mb-3">
                        <label htmlFor="source" className="form-label">Source From</label>
                        {loading ? (
                          <p>Loading sources...</p>
                        ) : errorMessage ? (
                          <p className="text-danger">{errorMessage}</p>
                        ) : (
                          <select
                            id="source"
                            className="form-select"
                            value={formik.values.source}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="source"
                          >
                            <option value="">Select Source</option>
                            {sources.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.source_from}
                              </option>
                            ))}
                          </select>
                        )}
                        {formik.touched.source && formik.errors.source && (
                          <small className="text-danger">{formik.errors.source}</small>
                        )}
                      </div>

                      <div className="col-md-5 mb-3">
                        <label htmlFor="file" className="form-label">Upload File</label>
                        <input
                          type="file"
                          className="form-control"
                          id="file"
                          name="file"
                          accept=".xls,.xlsx"
                          onChange={(event) => formik.setFieldValue("file", event.currentTarget.files[0])}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.file && formik.errors.file && (
                          <small className="text-danger">{formik.errors.file}</small>
                        )}
                      </div>

                      <div className="col-md-2 mt-4 mb-3">
                        <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                          {formik.isSubmitting ? 'Uploading...' : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ErrorLog key={refreshErrorLog} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadUpload;

